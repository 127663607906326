import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { LoaderProvider } from '@bluecodecom/loader';

import '@bluecodecom/fonts';

import { ThemeProvider } from '@bluecodecom/common/theme';

import { ApolloProviders } from '@bluecodecom/onboarding-webview/features/apollo';

import './i18n';

const App = lazy(() => import('./app'));

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fbq?: any;
  }
}

createRoot(document.getElementById('root') as HTMLElement).render(
  <LoaderProvider>
    <ThemeProvider observe>
      <BrowserRouter>
        <ApolloProviders.Apollo>
          <Suspense>
            <App />
          </Suspense>
        </ApolloProviders.Apollo>
      </BrowserRouter>
    </ThemeProvider>
  </LoaderProvider>,
);
