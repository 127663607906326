import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as BaseApolloProvider,
  HttpOptions,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';

import { configService } from '@bluecodecom/onboarding-webview/features/config';

/**
 * TS does not like mjs (╯°□°）╯︵ ┻━┻
 */
const createUploadLink =
  // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-explicit-any
  require('apollo-upload-client/createUploadLink.mjs') as any;

const ApolloProvider: FC<PropsWithChildren> = ({ children }) => {
  const [client, setClient] =
    useState<ApolloClient<NormalizedCacheObject> | null>(null);

  const initializedRef = useRef(false);

  const initialize = useCallback(async () => {
    initializedRef.current = true;

    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            onboarding: {
              keyArgs: false,
            },
          },
        },
      },
    });

    const errorLink = onError(({ networkError }) => {
      console.log({ networkError });
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    });

    const httpLink = createUploadLink.default({
      // uri: () => 'http://localhost:6789/client/graphql',
      credentials: 'include',
      uri: () =>
        `https://onboarding-api.${configService.domain}/client/graphql`,
    } as HttpOptions);
    const removeTypenameLink = removeTypenameFromVariables();

    setClient(
      new ApolloClient({
        link: ApolloLink.from([removeTypenameLink, errorLink, httpLink]),
        cache,
        connectToDevTools: true,
      }),
    );
  }, []);

  useEffect(() => {
    if (initializedRef.current) {
      return;
    }

    initialize();
  }, [initialize]);

  if (!client) {
    return null;
  }

  return <BaseApolloProvider client={client}>{children}</BaseApolloProvider>;
};

export default ApolloProvider;
